import React from 'react'
import classnames from 'classnames'
import { useStaticQuery, graphql } from 'gatsby'

import { Image } from '../UI'

import styles from './HomeCataki.module.css'

function HomeCataki() {
  const containerClassName = classnames('container', styles.container)
  const titleClassName = classnames('titleLarge', styles.title)

  const { mobile, googlePlay, appStore } = useStaticQuery(
    graphql`
      query HomeCataki {
        mobile: file(relativePath: { eq: "home/mobile.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        googlePlay: file(relativePath: { eq: "home/icons/google-play-button.svg" }) {
          publicURL
        }
        appStore: file(relativePath: { eq: "home/icons/app-store-button.svg" }) {
          publicURL
        }
      }
    `
  )

  return (
    <div className={containerClassName}>
      <Image
        alt='Conheça o Cataki'
        className={styles.image}
        fluid={mobile.childImageSharp.fluid}
      />

      <div className={styles.content}>
        <h2 className={titleClassName}>já conhece o cataki?</h2>
        <p className={styles.description}>
          É o app que conecta as pessoas e as empresas com os catadores de recicláveis. De um jeito bem simples, o
          Cataki cataloga cada catador, que ganha visibilidade num perfil organizado e cheio de informações. Assim, além
          de dar um destino correto aos resíduos, a gente pode ajudar no aumento da renda desses profissionais.
        </p>
        <div className={styles.apps}>
          <a
            href='https://play.google.com/store/apps/details?id=com.ionicframework.pimp473818&hl=pt_BR'
            rel='noopener noreferrer'
            target='_blank'
          >
            <img
              alt='Baixe na Play Store'
              className={styles.google}
              src={googlePlay.publicURL}
            />
          </a>
          <a
            href='https://apps.apple.com/br/app/cataki/id1250990464'
            rel='noopener noreferrer'
            target='_blank'
          >
            <img
              alt='Baixe na App Store'
              className={styles.apple}
              src={appStore.publicURL}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export { HomeCataki }
