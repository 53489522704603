import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import cn from 'classnames'
import { useMedia } from 'react-use'

import { Image, Button } from '../UI'
import { NEW_CRYSTAL } from '../../constants/routes'

import s from './HomeIntro.module.css'

function HomeIntro() {
  const { bottle, background, backgroundMobile } = useStaticQuery(
    graphql`
      query HomeIntro {
        background: file(relativePath: { eq: "home/intro/background.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        backgroundMobile: file(relativePath: { eq: "home/intro/background.png" }) {
          childImageSharp {
            fluid(srcSetBreakpoints: [ 340, 520, 900 ]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bottle: file(relativePath: { eq: "home/intro/bottle.png" }) {
          childImageSharp {
            fluid(maxWidth: 230) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const [animateImage, setAnimateImage] = useState(false)
  const [animateBottle, setAnimateBottle] = useState(false)
  const [animateText, setAnimateText] = useState(false)
  const [animateInfo, setAnimateInfo] = useState(false)

  const cnImage = cn(s.background, {
    [s.animateBackground]: animateImage
  })
  const cnBottle = cn(s.bottle, {
    [s.animateBottle]: animateBottle
  })
  const cnTextLeft = cn(s.text, s.textLeft, {
    [s.animateTextLeft]: animateText
  })
  const cnTextRight = cn(s.text, s.textRight, {
    [s.animateTextRight]: animateText
  })
  const cnInfo = cn(s.info, {
    [s.animateInfo]: animateInfo
  })

  useEffect(() => {
    setTimeout(() => {
      setAnimateText(!animateText)
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  const isDesktop = useMedia('(min-width: 971px)', initialWidth >= 971)
  const imageVideo = isDesktop ? background : backgroundMobile

  function renderBackground() {
    return (
      <Image
        alt=''
        className={cnImage}
        fluid={imageVideo.childImageSharp.fluid}
      />
    )
  }

  return (
    <div className={s.container}>
      <figure className={cnImage}>{renderBackground()}</figure>
      <div className={s.content}>
        <figure
          className={cnBottle}
          onAnimationEnd={() => setAnimateImage(true)}
        >
          <Image
            alt='Garrafa Crystal Sem Gás.'
            fluid={bottle.childImageSharp.fluid}
          />
        </figure>
        <div className={s.contentWrapper}>
          <h2
            className={cnTextLeft}
            onAnimationEnd={() => setAnimateInfo(true)}
          >
            o futuro é
          </h2>
          <h2 className={cnTextRight}>água e ponto.</h2>
          <div
            className={cnInfo}
            onAnimationEnd={() => setAnimateBottle(true)}
          >
            <p className={s.description}>
              A evolução natural de Crystal é garantir que a água chegue até você sem deixar resíduos espalhados pelo
              planeta.
            </p>
            <Button
              isMuted
              className={s.button}
              to={NEW_CRYSTAL}
            >
              Por que mudamos?
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { HomeIntro }
