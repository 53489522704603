import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { TIMELINE_CONTENT } from '../../constants/timeline'

import { Timeline } from '../UI'

function HomeTimeline() {
  const thumbs = useStaticQuery(
    graphql`
      query TimelineThumbs {
        allFile(filter: { relativeDirectory: { glob: "home/timeline/*" } }) {
          edges {
            node {
              name
              childImageSharp {
                original {
                  width
                  height
                }
                fluid(srcSetBreakpoints: [ 250, 340, 600 ]) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return (<Timeline
    thumbs={thumbs}
    content={TIMELINE_CONTENT}
  />)
}

export { HomeTimeline }
