import React from 'react'
import classnames from 'classnames'
import { useStaticQuery, graphql } from 'gatsby'

import { Accordion, Image } from '../UI'
import { useAccordionCities } from '../../hooks'

import styles from './HomeWaterSources.module.css'

function HomeWaterSources() {
  const accordionCities = useAccordionCities()
  const containerClassName = classnames('container', styles.container)
  const titleClassName = classnames('titleLarge', styles.title)

  const { brasil, waterHand, bottles, drop } = useStaticQuery(
    graphql`
      query HomeWaterSources {
        brasil: file(relativePath: { eq: "home/icons/brasil.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        waterHand: file(relativePath: { eq: "home/icons/water-hand.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bottles: file(relativePath: { eq: "home/icons/bottles.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        drop: file(relativePath: { eq: "home/icons/drop.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const sources = [
    {
      alt: 'Ícone do contorno territorial do Brasil.',
      image: brasil,
      classNameImage: styles.iconeBrasil,
      title: 'A água Crystal vem de fontes espalhadas pelo Brasil',
      description: `E não existe a história da gente ser dono de alguma fonte.
        Se você ainda não sabe, todas elas são parte de uma concessão pública.`
    },
    {
      alt: 'Ícone de uma mão com uma gota de água.',
      image: waterHand,
      classNameImage: styles.iconeWaterHand,
      title: 'A gente quer fazer com que a água chegue até você',
      description:
        `Foi por isso que, desde 2018, abrimos fontes de água potável aos moradores vizinhos de nossas fábricas.
        Até agora, temos fontes abertas em fábricas do Espírito Santo, São Paulo, Minas Gerais, Maranhão e Alagoas.`
    },
    {
      alt: 'Ícone de garrafas de água.',
      image: bottles,
      classNameImage: styles.iconeBottles,
      title: 'Você pode pegar sua própria água',
      description: 'Isso mesmo. Nas fontes abertas, qualquer pessoa pode pegar água sem nenhum custo.'
    },
    {
      alt: 'Ícone de uma gota de água.',
      image: drop,
      classNameImage: styles.iconeDrop,
      title: 'A água é potável?',
      description: 'Sim. A água disponível em todos esses pontos de hidratação é sempre potável.'
    }
  ]

  function renderSources() {
    const items = sources.map((f, index) => (
      <div
        key={index}
        className={styles.font}
      >
        <div className={styles.fontImage}>
          <Image
            alt={f.alt}
            className={f.classNameImage}
            fluid={f.image.childImageSharp.fluid}
          />
        </div>
        <div className={styles.fontContent}>
          <h2 className={styles.fontTitle}>{f.title}</h2>
          <p className={styles.fontDescription}>{f.description}</p>
        </div>
      </div>
    ))

    return <div className={styles.fonts}>{items}</div>
  }

  return (
    <section className={containerClassName}>
      <h2 className={titleClassName}>nossas fontes</h2>
      <div className={styles.content}>
        {renderSources()}
        <Accordion
          className={styles.accordionWrapper}
          itemIdPrefix='crystalWaterAccordionItem'
          items={accordionCities}
        />
      </div>
    </section>
  )
}

export { HomeWaterSources }
