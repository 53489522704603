import React from 'react'

import { SEO, VerticalAccordion } from '../components/UI'
import { HomeTimeline, HomeCataki, HomeWaterSources, HomeIntro, HomeGoal, HomeDelivery } from '../components/Home'

import { useImageSabores, useImageAgua } from '../hooks'

export default function Home() {
  const imageAgua = useImageAgua()
  const imageSabores = useImageSabores()

  return (
    <>
      <SEO title='Home' />
      <HomeIntro />
      <HomeDelivery />
      <HomeGoal />
      <HomeTimeline />
      <VerticalAccordion items={[...imageAgua, ...imageSabores]} />
      <HomeCataki />
      <HomeWaterSources />
    </>
  )
}
