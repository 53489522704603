import React, { useEffect } from 'react'
import { useAnimatedState } from 'framer-motion'
import noop from 'lodash/noop'

import Car from '../../images/home/icons/car.svg'
import Ship from '../../images/home/icons/ship.svg'
import Airplane from '../../images/home/icons/airplane.svg'

import s from './HomeGoal.module.css'

const GOAL_BOTTLES = 3500000000
const INIT_DATE = new Date('2020-10-27T03:00:00Z')
const FINAL_DATE = new Date('2025-04-02T03:00:00Z')

function getUpdatedBottleCount() {
  const now = new Date()
  return Math.round((GOAL_BOTTLES * getEllapsedTime(now)) / getEllapsedTime(FINAL_DATE))
}

function getEllapsedTime(date) {
  const timestampDiff = date - INIT_DATE
  return Math.round(timestampDiff / 1000)
}

function getFormattedNumber(number) {
  const characters = [...Math.round(number).toString()]

  return characters.reduce((fullStr, char, index) => {
    let newStr = fullStr + char
    const isPeriodNeeded = (characters.length - index - 1) % 3 === 0
    const isLastCharacter = index === characters.length - 1

    if (isPeriodNeeded && !isLastCharacter) {
      newStr += '.'
    }

    return newStr
  }, '')
}

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * ((max - min) + 1 + min))
}

function HomeGoal() {
  const [animatedState, setAnimatedState] = useAnimatedState({
    bottleCount: getUpdatedBottleCount(),
    transition: { duration: 1 }
  })

  useEffect(() => {
    let timer = noop

    function calc() {
      const timeout = getRandomNumber(2000, 6000)

      timer = setTimeout(() => {
        setAnimatedState({
          bottleCount: getUpdatedBottleCount(),
          transition: { duration: 1 }
        })

        calc()
      }, timeout)
    }

    calc()

    return () => clearTimeout(timer)
  }, [setAnimatedState])

  return (
    <div className={s.container}>
      <div className={s.content}>
        <div className={s.goal}>
          <h2>nossa meta:</h2>
          <p className={s.goalInfo}>
            <span className={s.white}>Ser água e ponto.</span> Até 2025, vamos deixar de colocar 3,5 bilhões de novas
            garrafas na rua.
          </p>
        </div>
        <div className={s.solution}>
          <h2>
            a solução: <span className={s.white}>reaproveitar.</span>
          </h2>
          <p className={s.solutionInfo}>
            Nossas embalagens já são 100% feitas de outras garrafas. Até 2025, evitaremos 50 mil toneladas de plástico
            novo. 50 mil toneladas? <span className={s.white}>Ficou díficil entender a dimensão disso?</span>
          </p>
        </div>
        <div className={s.equal}>
          <h2>
            isso <span className={s.white}>equivale a:</span>
          </h2>
          <div className={s.compare}>
            <div className={s.compareItem}>
              <Car className={s.compareIcon} />
              <div className={s.compareInfo}>
                <p>50.000</p>
                <p className={s.white}>carros populares</p>
              </div>
            </div>
            <div className={s.compareItem}>
              <Ship className={s.compareIcon} />
              <div className={s.compareInfo}>
                <p>1 Navio</p>
                <p className={s.white}>tamanho do Titanic (com combustível)</p>
              </div>
            </div>
            <div className={s.compareItem}>
              <Airplane className={s.compareIcon} />
              <div className={s.compareInfo}>
                <p>1.000</p>
                <p className={s.white}>aviões comerciais</p>
              </div>
            </div>
          </div>
        </div>
        <div className={s.realTime}>
          <p className={s.white}>Acompanhe em tempo real quantas garrafas novas nós já evitamos até agora:</p>
          <p>{getFormattedNumber(animatedState.bottleCount)}</p>
        </div>
      </div>
    </div>
  )
}

export { HomeGoal }
