import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Button } from '../UI'
import { ACCESS_WATER } from '../../constants/routes'

import styles from './HomeDelivery.module.css'

function HomeDelivery() {
  const { globe, petBottle, recycle } = useStaticQuery(
    graphql`
      query HomeDelivery {
        recycle: file(relativePath: { eq: "home/icons/recycle.svg" }) {
          publicURL
        }
        petBottle: file(relativePath: { eq: "home/icons/pet-bottle.svg" }) {
          publicURL
        }
        globe: file(relativePath: { eq: "home/icons/globe.svg" }) {
          publicURL
        }
      }
    `
  )

  return (
    <section className={styles.container}>
      <div className={`${styles.content} ${styles.contentLeft}`}>
        <h3 className={styles.introHeader}>
          Evoluir sempre é o que a gente quer. <br />
          E o planeta, também. <br className={styles.mobileHidden} />
          Qual é a nossa <br className={styles.desktopHidden} /> contribuição <br className={styles.mobileHidden} />
          para que o futuro aconteça?
        </h3>
        <h2 className={`titleLarge ${styles.title}`}>
          levar água <br className={styles.desktopHidden} />
          por aí <br className={styles.mobileHidden} />
          sem <br className={styles.desktopHidden} />
          deixar resíduos.
        </h2>
        <Button
          isMuted
          className={`${styles.button} ${styles.mobileHidden}`}
          to={ACCESS_WATER}
        >
          Descubra como estamos criando o futuro
        </Button>
      </div>
      <div className={`${styles.content} ${styles.contentRight}`}>
        <div className={styles.symbols}>
          <img
            alt='Icone recilagem'
            className={styles.google}
            src={recycle.publicURL}
          />
          <img
            alt='Icone embalagem sustentável'
            className={styles.google}
            src={petBottle.publicURL}
          />
          <img
            alt='Icone global'
            className={styles.google}
            src={globe.publicURL}
          />
        </div>
        <div className={styles.articleWrapper}>
          <p className={styles.article}>
            Se já existe muito plástico poluindo e impactando o meio ambiente por aí, porque não reaproveitar?
          </p>
          <p className={styles.article}>
            Para e pensa um pouquinho no nosso estilo de vida. Você também percebeu que algumas coisas não estão lá
            fazendo muito sentido? A gente quer um futuro que seja legal para todo mundo e muito melhor do que o
            presente. Mas esse futuro é realmente possível?
          </p>
          <p className={styles.article}>
            Sim, é. Com uma condição: todo mundo precisa fazer algo para a criação dele. Nós estamos aqui e já somos
            parte dessa corrente de mudança. E então, o que a tem gente tem feito? Nos adaptamos.
          </p>
          <p className={styles.article}>
            A nossa embalagem é hoje resultado dessa transformação: 100% feita de outras garrafas. O nosso caminho para
            o futuro já está traçado: não colocar mais plástico novo no mundo.
          </p>
          <p className={styles.article}>
            Vem fazer parte disso com a gente. Vamos criar juntos o futuro que todos nós queremos?
          </p>
        </div>
      </div>
      <Button
        isMuted
        className={`${styles.button} ${styles.desktopHidden}`}
        to={ACCESS_WATER}
      >
        Descubra como estamos criando o futuro
      </Button>
    </section>
  )
}

export { HomeDelivery }
